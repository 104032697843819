import { Footer, Layout, PageLayout } from '@hp/layout';
import { Navigation, UnauthenticatedLinkGroup } from '@hp/navigation';
import { Homepage as Content, ParcelShop } from '@hp/order';
import { QueryParams } from '@hp/seo';
import { NextPage, NextPageContext } from 'next';
import React from 'react';

type HomepageProps = {
  selectedParcelShop?: ParcelShop;
};

const Index: NextPage<HomepageProps> = ({ selectedParcelShop }) => {
  return (
    <Layout metaRoute="default">
      <Navigation>
        <UnauthenticatedLinkGroup />
      </Navigation>

      <PageLayout>
        <Content selectedParcelShop={selectedParcelShop} />
      </PageLayout>

      <Footer />
    </Layout>
  );
};

Index.getInitialProps = async ({ query }: NextPageContext) => {
  return ({
    selectedParcelShop: query[QueryParams.body],
  } as unknown) as HomepageProps;
};

export default Index;
